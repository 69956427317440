
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonText,
  //IonAvatar,
  IonButton,
  IonList,
} from "@ionic/vue";
import { useRouter } from "vue-router";

import { dbUser, dbCustomers } from "../services/localbase";

export default {
  name: "Tab4",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonItem,
    IonLabel,
    IonText,
    //IonAvatar,
    IonButton,
    IonList,
  },
  setup() {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const userImage = `https://crm.multilink.net/imgn/1/100/100/uploads/${user.users_avatar}`;
    const router = useRouter();

    /**
     *
     * Set user avatar image
     *
     */
    function setImage(user) {
      if (user.users_avatar != null || user.users_avatar != "") {
        return `https://crm.multilink.net/imgn/1/100/100/uploads/${user.users_avatar}`;
      } else {
        return `https://crm.multilink.net/images/users.png`;
      }
    }

    /**
     *
     * Delete localbase CLIENTI and USER database
     *
     */
    async function deleteDb() {
      const deleteCustomer = await dbCustomers.collection("clienti").delete();
      const deleteUser = await dbUser.collection("user").delete();
      // console.log(deleteUser);
      // console.log(deleteCustomer);
      /*dbCustomers.delete();
      localStorage.clear();
      router.replace("/login");*/
    }

    /**
     *
     * Delete Localbase database and clear localStorage
     * Redirect user to Login page
     *
     */
    function logOut() {
      deleteDb().then(() => {
        localStorage.clear();
        router.replace("/login");
      });
    }

    /**
     *
     * Force app update
     *
     */
    function updateApp() {
      window.location.reload(true);
    }

    return {
      user,
      setImage,
      userImage,
      logOut,
      updateApp,
    };
  },
};
